import ApiService from './api.service';

const TributeSongService = {
    getSongs(params, queryParams = {}) {
        let url = 'TributeVideoSong';

        const queryString = new URLSearchParams();

        // Add genre parameters to the query string
        if (queryParams.genre && queryParams.genre.length > 0) {
            queryParams.genre.forEach(genre => {
                queryString.append('genre', genre);
            });
        }

        // Add keyword parameters to the query string
        if (queryParams.keyword && queryParams.keyword.length > 0) {
            queryParams.keyword.forEach(keyword => {
                queryString.append('keyword', keyword);
            });
        }

        if (queryString.toString()) {
            url += `?${queryString.toString()}`;
        }

        return ApiService.get(url, { params });
    },

    getSong(id) {
        return ApiService.get(`TributeVideoSong/${id}`);
    },

    updateSong(id, data) {
        return ApiService.put(`TributeVideoSong/${id}`, data);
    },

    deleteSong(id) {
        return ApiService.delete(`TributeVideoSong/${id}`);
    },

    getGenres() {
        return ApiService.get('MusicGenre', { params: { pageSize: 100 } });
    },

    addGenre(songId, genreId) {
        return ApiService.put(`TributeVideoSong/genre/add/${songId}/${genreId}`);
    },

    removeGenre(songId, genreId) {
        return ApiService.put(`TributeVideoSong/genre/remove/${songId}/${genreId}`);
    },

    createGenre(data) {
        return ApiService.post('MusicGenre', data);
    },

    updateGenre(id, data) {
        return ApiService.put(`MusicGenre/${id}`, data);
    },

    deleteGenre(id) {
        return ApiService.delete(`MusicGenre/${id}`);
    },

    getKeywords() {
        return ApiService.get('MusicKeyword', { params: { pageSize: 100 } });
    },

    addKeyword(songId, keywordId) {
        return ApiService.put(`TributeVideoSong/keyword/add/${songId}/${keywordId}`);
    },

    removeKeyword(songId, keywordId) {
        return ApiService.put(`TributeVideoSong/keyword/remove/${songId}/${keywordId}`);
    },

    createKeyword(data) {
        return ApiService.post('MusicKeyword', data);
    },

    updateKeyword(id, data) {
        return ApiService.put(`MusicKeyword/${id}`, data);
    },

    deleteKeyword(id) {
        return ApiService.delete(`MusicKeyword/${id}`);
    },

    toggleStaffFavorite(songId, val) {
        return ApiService.post(`TributeVideoSong/toggle-staff-favorite/${songId}/${val}`);
    },

    updateSong(id, data) {
        return ApiService.put(`TributeVideoSong/${id}`, data);
    },

    getCopyrightSafeSongs(tributeVideoId) {
        return ApiService.get(`TributeVideo/selected-songs/${tributeVideoId}?copyrightSafe=true`);
    },

    updateSelectedSongs(tributeVideoId, data) {
        return ApiService.post(`TributeVideo/selected-songs/${tributeVideoId}`, data);
    },

    updateSongArtwork(id, fileName) {
        console.log('UPDATING ARTWORK', fileName);
        return ApiService.put(`TributeVideoSong/artwork/${id}`, JSON.stringify(fileName), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    deleteSongArtwork(id) {
        return ApiService.put(`TributeVideoSong/artwork/${id}`, JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    updateSongLicense(id, fileName) {
        return ApiService.put(`TributeVideoSong/song-license/${id}`, JSON.stringify(fileName), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    deleteSongLicense(id) {
        return ApiService.put(`TributeVideoSong/song-license/${id}`, JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
};

export default TributeSongService;
