import axios from 'axios';

const apiAxios = axios.create();

const ApiService = {
    init(baseURL) {
        apiAxios.defaults.baseURL = baseURL;
    },

    setToken(token) {
        apiAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },

    get(resource, config = {}) {
        return apiAxios.get(resource, config);
    },

    post(resource, data, config = {}) {
        return apiAxios.post(resource, data, config);
    },

    put(resource, data, config = {}) {
        return apiAxios.put(resource, data, config);
    },

    delete(resource, config = {}) {
        return apiAxios.delete(resource, config);
    },
};

export default ApiService;
