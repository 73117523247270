<template>
    <div>
        <!-- visible overflow allows staff pick labels to overflow window -->
        <v-window class="visible-overflow" v-model="window">
            <v-window-item key="0">
                <multi-playlist-wrapper
                    ref="multiPlaylistWrapper"
                    :token="token"
                    :disableDragDrop="mobile"
                    :showCopyrightPlaylist="showCopyrightPlaylist"
                    @refresh-sync="$emit('refresh-sync')"
                ></multi-playlist-wrapper>

                <div class="my-8">
                    <div class="my-2 d-flex justify-space-between flex-wrap">
                        <h3 class="mr-2">Top Picks</h3>
                        <!-- <v-btn @click="showAllFeatured" small depressed>More Picks</v-btn> -->
                        <v-btn color="primary" dark small depressed @click="showAllSongs">All Music</v-btn>
                    </div>
                    <featured-music
                        ref="featuredMusic"
                        @show-all-featured="showAllFeatured"
                        :token="token"
                        @refresh-sync="$emit('refresh-sync')"
                    ></featured-music>
                </div>

                <div class="my-8">
                    <div class="my-2 d-flex justify-space-between flex-wrap">
                        <h3 class="mr-2">Select Music</h3>
                    </div>

                    <div v-if="loading" class="grid">
                        <div v-for="num in 10" class="grid-item">
                            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
                        </div>
                    </div>
                    <div v-else class="grid">
                        <div
                            v-if="$route.name != 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'"
                            @click="initUploader"
                            class="grid-item grid-item-btn"
                        >
                            <strong class="title">Upload</strong>
                            <font-awesome-icon
                                style="font-size: 2rem"
                                icon="fa-regular fa-circle-plus"
                            ></font-awesome-icon>
                        </div>

                        <div @click="getSongsByGenre(genre)" class="grid-item" v-for="genre in genres" :key="genre.id">
                            <v-img
                                v-if="genre.image"
                                class="img fill-height"
                                cover
                                :src="handleImgSrc(genre.image)"
                                max-width="100%"
                            ></v-img>
                            <h5 class="title">{{ genre.name }}</h5>
                            <div class="overlay"></div>
                        </div>
                    </div>
                </div>
            </v-window-item>

            <v-window-item eager id="music-table-window" key="1">
                <div style="position: relative">
                    <div class="mt-4 d-flex justify-end">
                        <!-- <v-btn @click="returnToGenresGrid" depressed small>
                            <font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left"></font-awesome-icon>
                            Back</v-btn
                        > -->

                        <v-btn
                            v-if="$route.name != 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'"
                            dark
                            depressed
                            small
                            color="#ff530d"
                            @click="initUploader"
                            >Upload +</v-btn
                        >
                    </div>

                    <tribute-playlist
                        v-if="event"
                        :funeralHomeId="event.funeralHomeId"
                        :filteredGenres="selectedGenres"
                        :featuredOnly="featuredOnly"
                        :token="token"
                        :multiPlaylist="showCopyrightPlaylist"
                        ref="playlist"
                        @refresh-sync="$emit('refresh-sync')"
                        @back="returnToGenresGrid"
                    ></tribute-playlist>
                </div>
            </v-window-item>
        </v-window>

        <tribute-music-uploader
            :token="token"
            ref="musicUploader"
            v-if="$route.name != 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'"
            :funeralHomeId="event.funeralHomeId"
            hide-button
            @refresh="refreshSongs()"
            @upload-success="resp => handleUploadSuccess(resp)"
        />
    </div>
</template>
<script>
import TributePlaylist from '@/components/ManageService/Tribute/TributePlaylist.vue';
import FeaturedMusic from '@/components/ManageService/Tribute/FeaturedMusic.vue';
import TributeMusicUploader from '@/components/ManageService/Tribute/TributeMusicUploader.vue';
import MultiPlaylistWrapper from '@/components/ManageService/Tribute/MultiPlaylistWrapper';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            genres: [],
            totalGenres: 0,
            window: 0,
            selectedGenres: [],
            featuredOnly: false,
            loading: true,
            funeralHomeId: 0,
        };
    },
    components: {
        TributePlaylist,
        FeaturedMusic,
        TributeMusicUploader,
        MultiPlaylistWrapper,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        parentTabIndex: {
            type: String,
            required: true,
        },
        showCopyrightPlaylist: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        window() {
            this.stopAllMusicPlayers();
            if (this.window == 1) {
                this.scrollUp();
            }

            if (this.window == 0) {
                this.refreshCopyrightSongs();
            }
        },
        parentTabIndex(newVal) {
            this.stopAllMusicPlayers();

            this.window = 0;
        },
    },
    computed: {
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideoSelectedSongs']),
        refreshCopyrightSongs() {
            if (this.$refs.multiPlaylistWrapper) {
                this.$refs.multiPlaylistWrapper.refreshCopyrightPlaylist();
            }
        },
        async handleUploadSuccess(resp) {
            await this.toggleSelected(resp);
        },
        updateSelectedSongs(id, ids, successMessage = '') {
            let data = {
                selectedSongs: ids,
            };
            return this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, data)
                .then(resp => {
                    if (successMessage) {
                        this.showSnackbar({ message: successMessage });
                    }
                })
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async toggleSelected(item) {
            const found = this.selectedSongs.find(x => x.id == item.id);

            var listClone = [...this.selectedSongs];

            if (found) {
                const indexToRemove = listClone.indexOf(found);
                listClone.splice(indexToRemove, 1);
                for (let i = indexToRemove; i < listClone.length; i++) {
                    listClone[i].order = i;
                }
            } else {
                listClone.push({ ...item, order: this.selectedSongs.length });
            }

            this.updateTributeVideoSelectedSongs(listClone);
            const selectedIds = listClone.map(obj => obj.id);

            var message = '';

            if (found) {
                message = 'Removed from to playlist';
            } else {
                message = 'Added to playlist';
            }

            await this.updateSelectedSongs(this.tributeVideo.id, selectedIds, message);
            this.$emit('refresh-sync');
        },
        stopAllMusicPlayers() {
            if (this.$refs.featuredMusic) {
                this.$refs.featuredMusic.stopPlayingAudio();
            }

            if (this.$refs.playlist) {
                this.$refs.playlist.stopAllAudio();
            }

            if (this.$refs.selectedSummary) {
                this.$refs.selectedSummary.stopAllAudio();
            }
        },
        initUploader() {
            if (this.$refs.musicUploader) {
                this.$refs.musicUploader.handleOpen();
            }
        },
        refreshSongs() {
            if (this.$refs.playlist) {
                this.$refs.playlist.refreshSongs();
            }
        },
        handleImgSrc(url, lowres = false) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/genre-images/')[1];
                if (lowres) {
                    return process.env.VUE_APP_IMG_KIT_BASE + 'genre-images/' + 'tr:w-50,h-50/' + imgPath;
                } else {
                    return process.env.VUE_APP_IMG_KIT_BASE + 'genre-images/' + 'tr:w-250,h-250/' + imgPath;
                }
            }

            return url;
        },
        showAllSongs() {
            this.selectedGenres = [];
            this.featuredOnly = false;
            this.window = 1;
        },
        scrollUp() {
            const targetEl = document.getElementById('tribute-avatar');
            if (targetEl) {
                targetEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
        },
        showAllFeatured() {
            this.selectedGenres = [];
            this.featuredOnly = true;
            this.window = 1;
        },
        returnToGenresGrid() {
            this.window = 0;
            this.selectedGenres = [];
            this.featuredOnly = false;
            if (this.$refs.playlist) {
                this.$refs.playlist.search = '';
            }
        },
        getSongsByGenre(item) {
            this.selectedGenres = [item.id];
            this.window = 1;
        },
        getKeywords() {
            return this.axiosInstance
                .get('/MusicKeyword?pageSize=100')
                .then(res => {
                    this.keywords = res.data.musicKeywords;
                    this.totalKeywords = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        getGenres() {
            this.loading = true;

            return this.axiosInstance
                .get('/MusicGenre?pageSize=100')
                .then(res => {
                    this.genres = res.data.musicGenres;
                    this.totalGenres = res.data.total;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        this.createAxiosInstance();

        this.getGenres();
    },
};
</script>
<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    // justify-items: start;
    gap: 20px;
}

.grid-item {
    cursor: pointer;
    aspect-ratio: 3/4;
    background-color: #4b4b4b;
    color: white;
    border-radius: 5px;
    position: relative;
    width: 100%;

    .title {
        position: absolute;
        bottom: 25px;
        width: 100%;
        text-align: center;
        z-index: 3;
    }
    .img {
        border-radius: 5px;
    }
}

.grid-item-btn {
    background-color: #f8f8f8;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.25rem;
    transition: 0.2s;
}
.grid-item-btn:hover {
    background-color: #eee;
}

.overlay {
    position: absolute; /* Absolute positioning */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    color: white; /* Text color */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 2; /* Sit on top */
    border-radius: 5px;
    transition: 0.2s;
}

.overlay:hover {
    background-color: transparent !important; /* Semi-transparent background */
}

.title:hover + .overlay {
    background-color: transparent !important; /* Semi-transparent background */
}

.visible-overflow {
    overflow: visible;
}

@media (max-width: 400px) {
    .grid {
        justify-items: center;
    }
    .grid-item {
        max-width: 200px;
    }
}
</style>
