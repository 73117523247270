<template>
    <div>
        <div class="text-center fill-height d-flex justify-content-center align-center" v-if="!mounted">
            <v-progress-circular size="50" indeterminate></v-progress-circular>
        </div>

        <div v-if="mounted">
            <div class="text-center" style="width: 100%" v-if="onboarding">
                <h1 v-if="service" style="font-size: 36px; font-weight: 800" class="text-heading text-center">
                    Share {{ service.firstName }}'s Story
                </h1>
                <h1 v-else style="font-size: 36px; font-weight: 800" class="text-heading text-center">Story</h1>
            </div>
            <div v-else>
                <v-row>
                    <v-col>
                        <div style="display: flex; align-items: center">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <v-avatar
                                        @click="profileUploadOpen"
                                        class="mr-2 pointer"
                                        v-if="story.profilePhotoUrl"
                                        size="70"
                                    >
                                        <v-img
                                            :src="story.profilePhotoUrl"
                                            :lazy-src="story.profilePhotoUrl"
                                            alt="Avatar"
                                        />
                                    </v-avatar>
                                    <v-avatar
                                        @click="profileUploadOpen"
                                        color="#f1f4f7"
                                        class="mr-2 pointer"
                                        v-else
                                        size="70"
                                    >
                                        <h3 class="m-0">
                                            {{ service.firstName[0].toUpperCase() + service.lastName[0].toUpperCase() }}
                                        </h3>
                                    </v-avatar>
                                </template>

                                <template v-slot:content>
                                    <span>Update Profile Picture</span>
                                </template>
                            </custom-tooltip>
                            <div style="display: flex; flex-direction: column; justify-content: center">
                                <h3>{{ service.firstName ? service.firstName : '' }}'s Story</h3>
                                <p
                                    @click="deadlineModal = true"
                                    class="m-0 pointer deadline-link"
                                    style="color: #999999"
                                >
                                    <font-awesome-icon icon="fa-regular fa-calendar-circle-exclamation" />
                                    <span v-if="story.deadline">
                                        {{ $moment(story.deadline).format('MMM Do, h:mm a') }}
                                    </span>

                                    <span class="text-decoration-underline ml-2" v-else>Set a Deadline </span>
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-end">
                        <div style="max-width: 100%">
                            <v-tabs show-arrows v-model="tabIndex">
                                <v-tab v-for="(tab, index) in storyTabs" :key="index" :href="'#' + tab.value">{{
                                    tab.label
                                }}</v-tab>
                            </v-tabs>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-3">
                <v-window style="overflow: visible" v-model="windowIndex">
                    <v-window-item>
                        <story-onboarding
                            ref="storyOnboarding"
                            @profile-uploader-click="profileUploadOpen"
                            @story-uploader-open="storyUploaderOpen"
                            @update-story="getStory"
                            @init-invite="initInvite"
                            @download-qr-pdf="downloadQrPdf"
                            @force-finish-onboarding="onboarding = false"
                            :service="service"
                            :event="event"
                        ></story-onboarding>
                    </v-window-item>
                    <v-window-item>
                        <v-tabs-items style="overflow: visible" v-model="tabIndex">
                            <v-tab-item class="pt-5" value="video">
                                <div v-if="render">
                                    <div class="text-right mb-3">
                                        <v-btn @click="deleteRenderStep1" x-small color="error">{{
                                            render.status == 0 || render.status == 1 ? 'Cancel' : 'Delete'
                                        }}</v-btn>
                                    </div>
                                    <div v-if="render.status == 0 || render.status == 1" class="text-center">
                                        <div class="d-flex justify-center m-5">
                                            <custom-loader></custom-loader>
                                        </div>
                                        <h5 class="mt-6">Creating Stories Video. This will take a few minutes.</h5>
                                    </div>

                                    <div v-if="render.status == 2">
                                        <branding-player-preview
                                            :key="refreshKey"
                                            ref="videoPlayer"
                                            :show-title="false"
                                            :source="render.url"
                                        ></branding-player-preview>

                                        <div class="text-center ma-3">
                                            <v-btn
                                                color="#0d3d60"
                                                :dark="!loading"
                                                :disabled="loading"
                                                @click="downloadVideo(render.url, `${service.firstName}'s Story Video`)"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-arrow-down-to-bracket"
                                                    style="font-size: 0.9rem"
                                                    class="mr-2"
                                                />
                                                <span>Download</span>
                                            </v-btn>

                                            <v-btn
                                                class="ml-2"
                                                v-if="service.slug && storyVideoLink"
                                                :disabled="loading"
                                                @click="initStoryVideoShareModal"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-share"
                                                    style="font-size: 0.9rem"
                                                    class="mr-2"
                                                />
                                                <span>Share</span>
                                            </v-btn>
                                        </div>
                                    </div>

                                    <div v-if="render.status == 3">
                                        <v-alert outlined text class="text-center" type="error"
                                            >Error occurred during render</v-alert
                                        >
                                    </div>
                                </div>
                                <div class="mt-3 d-flex justify-space-between">
                                    <v-spacer></v-spacer>

                                    <v-btn @click="tabIndex = 'stories'" depressed
                                        >Stories

                                        <font-awesome-icon
                                            class="ml-2"
                                            icon="fa-regular fa-chevron-right"
                                        ></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </v-tab-item>

                            <v-tab-item value="share">
                                <story-share-tab
                                    :contributeLink="story.contributeLink"
                                    @init-invite="initInvite"
                                    @download-qr-pdf="downloadQrPdf"
                                    @display-tv-qr="displayTvQr"
                                ></story-share-tab>

                                <div class="d-flex justify-space-between mt-3">
                                    <v-btn v-if="render != null" @click="tabIndex = 'video'" depressed>
                                        <font-awesome-icon
                                            class="mr-2"
                                            icon="fa-regular fa-chevron-left"
                                        ></font-awesome-icon>
                                        Video
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="tabIndex = 'stories'" depressed
                                        >Stories

                                        <font-awesome-icon
                                            class="ml-2"
                                            icon="fa-regular fa-chevron-right"
                                        ></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </v-tab-item>

                            <v-tab-item eager value="stories">
                                <tribute-stories
                                    v-show="storiesMounted"
                                    @init-invite="initInvite"
                                    @mounted="storiesMounted = true"
                                    @stories-count="val => updateStoriesCount(val)"
                                    @open-settings="settingsModal = true"
                                    @render-stories="eventId => renderStories(eventId)"
                                    :service="service"
                                    :event="event"
                                    :story="story"
                                ></tribute-stories>

                                <div class="d-flex justify-space-between mt-3">
                                    <v-btn v-if="render == null" @click="tabIndex = 'share'" depressed>
                                        <font-awesome-icon
                                            class="mr-2"
                                            icon="fa-regular fa-chevron-left"
                                        ></font-awesome-icon>
                                        Share
                                    </v-btn>
                                    <v-btn v-if="render != null" @click="tabIndex = 'video'" depressed>
                                        <font-awesome-icon
                                            class="mr-2"
                                            icon="fa-regular fa-chevron-left"
                                        ></font-awesome-icon>
                                        Video
                                    </v-btn>

                                    <v-btn @click="tabIndex = 'summary'" depressed
                                        >Summary

                                        <font-awesome-icon
                                            class="ml-2"
                                            icon="fa-regular fa-chevron-right"
                                        ></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </v-tab-item>

                            <v-tab-item value="summary">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-center fill-height">
                                            <div v-if="totalSafeStories == 0">
                                                <v-alert outlined text type="info">
                                                    No safe stories found.
                                                    <u class="pointer" @click="tabIndex = 'stories'">Click here</u>
                                                    to add stories.
                                                </v-alert>
                                            </div>

                                            <div class="my-3" v-if="showPreview">
                                                <h4 class="mb-3 text-center ms-orange">Preview</h4>
                                                <p class="text-caption">
                                                    {{ totalSafeStories }}
                                                    {{ totalSafeStories == 1 ? 'story' : 'stories' }} included.
                                                    <span v-if="unsafeStories > 0">
                                                        {{ unsafeStories }}
                                                        {{ unsafeStories == 1 ? 'story' : 'stories' }}
                                                        excluded.
                                                    </span>
                                                </p>
                                                <creatomate-preview
                                                    :source="previewSource"
                                                    :modifications="previewMods"
                                                    ref="preview"
                                                    class="preview-player"
                                                ></creatomate-preview>
                                            </div>

                                            <v-btn
                                                class="mt-3"
                                                v-if="totalSafeStories > 0"
                                                @click="renderStories(event.id)"
                                                color="#ff530d"
                                                :disabled="disableCreateStoryRender"
                                                :loading="loading"
                                                :dark="!disableCreateStoryRender"
                                                depressed
                                                >{{ !render ? 'Create' : 'Recreate' }} Stories Video</v-btn
                                            >
                                        </div>
                                    </v-col>
                                </v-row>
                                <div class="d-flex justify-space-between mt-3">
                                    <v-btn @click="tabIndex = 'stories'" depressed>
                                        <font-awesome-icon
                                            class="mr-2"
                                            icon="fa-regular fa-chevron-left"
                                        ></font-awesome-icon>
                                        Stories
                                    </v-btn>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-window-item>
                </v-window>
            </div>

            <media-uploader
                v-if="story.eventId"
                ref="storyProfileUploader"
                :token="token"
                :itemId="story.eventId"
                sasEndpoint="Story/profile-picture/sas"
                updateEndpoint="Story/profile-picture/update"
                @upload-success="refreshProfile"
            ></media-uploader>

            <tribute-story-uploader
                v-if="token && story.eventId"
                ref="storyUploader"
                @refresh="refreshTributeStories(story.eventId)"
                :eventId="story.eventId"
                :hide-button="true"
                :token="token"
            ></tribute-story-uploader>

            <!-- START: Settings Modal -->
            <v-dialog @click:outside="cancelDeadlineUpdate" max-width="400px" v-model="deadlineModal">
                <v-card class="tribute-settings-modal">
                    <v-card-title>Settings</v-card-title>
                    <!-- <v-card-text>Delete tribute event for {{ service.firstName }} {{ service.lastName }}?</v-card-text> -->
                    <div class="calendar-wrapper px-6">
                        <calendar
                            ref="deadlineCalendar"
                            datetime
                            v-model="tempDeadline"
                            label="Deadline"
                            :output-local="true"
                            disable-past-dates
                            hide-save-buttons
                        />
                    </div>

                    <div class="p-4 pt-0 d-flex" style="justify-content: space-between">
                        <v-btn @click="cancelDeadlineUpdate" depressed>Cancel</v-btn>
                        <v-btn @click="updateStoryDeadline" dark depressed color="primary">Confirm</v-btn>
                    </div>
                </v-card>
            </v-dialog>
            <!-- END: Settings Modal -->

            <link-share-modal
                v-if="story.contributeLink"
                ref="storyLinkShareModal"
                :title="`Contribute to ${service.firstName}'s Tribute Story`"
                :link="story.contributeLink"
                :qrFileName="`${service.firstName}'s Tribute Story`"
                @submit-invites="contacts => handleInviteSubmit(contacts, story.contributeLink)"
                @invalidEmail="email => handleInvalidEmail(email)"
            ></link-share-modal>

            <link-share-modal
                v-if="storyVideoLink"
                ref="storyVideoShareModal"
                :title="`Share ${service.firstName}'s Tribute Story`"
                :link="storyVideoLink"
                :qrFileName="`${service.firstName}'s Tribute Story`"
                @submit-invites="contacts => handleInviteSubmit(contacts, storyVideoLink)"
                @invalidEmail="email => handleInvalidEmail(email)"
            ></link-share-modal>

            <!-- tribute qr code pdf printout  -->
            <qr-printout
                v-if="story.contributeLink"
                :key="printoutRefreshKey"
                title="Tribute Story"
                :service="service"
                :link="story.contributeLink"
                ref="qrStoryPrint"
            />
            <!-- tribute qr code pdf printout  -->

            <branded-modal @close="settingsModal = false" max-width="500px" v-model="settingsModal">
                <template v-slot:title>
                    <h2>Settings</h2>
                </template>

                <template v-slot:body>
                    <v-card-text>
                        <v-switch inset v-model="publicEvent" label="Show on Video Player"></v-switch>
                        <v-text-field label="Phone Number" v-mask="'###.###.####'" v-model="phoneNumber"></v-text-field>
                        <v-text-field v-if="story.qrVideoUrl" readonly v-model="story.qrVideoUrl" label="Qr Code Video">
                            <template v-slot:append-outer>
                                <div class="d-flex" style="gap: 10px">
                                    <custom-tooltip :tooltipProps="{ top: true }">
                                        <template v-slot:activator>
                                            <v-btn small @click="copyToClipboard(story.qrVideoUrl)" dark depressed>
                                                <font-awesome-icon icon="fa-regular fa-clone"></font-awesome-icon>
                                            </v-btn>
                                        </template>

                                        <template v-slot:content>
                                            <span>Copy Link</span>
                                        </template>
                                    </custom-tooltip>

                                    <custom-tooltip
                                        v-if="$auth.role.includes('SuperAdmin')"
                                        :tooltipProps="{ top: true }"
                                    >
                                        <template v-slot:activator>
                                            <v-btn small @click="refreshStoryQrVideo" depressed>
                                                <font-awesome-icon
                                                    icon="fa-regular fa-rotate-right"
                                                ></font-awesome-icon>
                                            </v-btn>
                                        </template>

                                        <template v-slot:content>
                                            <span>Refresh Qr Video</span>
                                        </template>
                                    </custom-tooltip>
                                </div>
                            </template>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn depressed @click="settingsModal = false">Close</v-btn>
                        <v-btn
                            depressed
                            color="#0d3d60"
                            :loading="loading"
                            :disabled="loading"
                            :dark="!loading"
                            @click="saveSettings"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </template>
            </branded-modal>

            <v-dialog persistent v-model="downloading" max-width="500px">
                <v-card class="p-3">
                    <div class="d-flex justify-content-between">
                        <h5>Downloading</h5>
                        <p>{{ downloadProgress }}%</p>
                    </div>
                    <v-progress-linear rounded v-model="downloadProgress"></v-progress-linear>
                    <v-btn depressed class="mt-3" color="error" @click="cancelDownload">Cancel</v-btn>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deleteRenderModal" max-width="400px">
                <v-card>
                    <v-card-title>Delete Render?</v-card-title>
                    <v-card-text>Please confirm to delete render, this action cannot be undone.</v-card-text>

                    <div class="p-3">
                        <v-btn depressed @click="confirmDeleteRender(event.id)" color="error">Confirm</v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import StoryOnboarding from '@/components/ManageService/Tribute/StoryOnboarding.vue';
import TributeStories from '@/components/ManageService/Tribute/TributeStories.vue';
import MediaUploader from '@/components/ui/MediaUploader.vue';
import Calendar from '@/components/ui/Calendar.vue';
import LinkShareModal from '@/components/ui/LinkShareModal.vue';
import QrPrintout from '@/components/ManageService/Tribute/QrPrintout.vue';
import TributeStoryUploader from '@/components/ManageService/Tribute/TributeStoryUploader.vue';
import { mapActions } from 'vuex';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import StoryShareTab from '@/components/ManageService/Tribute/StoryShareTab.vue';
import CreatomatePreview from '@/components/Misc/CreatomatePreview.vue';
import CustomLoader from '@/components/ui/CustomLoader.vue';
import BrandingPlayerPreview from '@/components/videojs/BrandingPlayerPreview.vue';

export default {
    data() {
        return {
            story: {},
            totalTributeStories: 0,
            totalSafeStories: 0,
            token: '',
            reloadCount: 0,
            render: null,
            storiesMounted: false,
            deadlineModal: false,
            settingsModal: false,
            tempDeadline: null,
            printoutRefreshKey: 0,
            publicEvent: true,
            phoneNumber: '',
            loading: true,
            onboardingOverride: false,
            onboarding: true,
            tabIndex: 'share',
            mounted: false,
            windowIndex: 0,
            storyTabs: [
                { label: 'Share', value: 'share' },
                { label: 'Stories', value: 'stories' },
                { label: 'Summary', value: 'summary' },
            ],
            previewSource: '',
            previewMods: null,
            refreshKey: 0,
            downloadCancelToken: null,
            downloadProgress: 0,
            downloading: false,
            deleteRenderModal: false,
            storyVideoLink: '',
        };
    },
    components: {
        StoryOnboarding,
        TributeStories,
        MediaUploader,
        TributeStoryUploader,
        Calendar,
        LinkShareModal,
        QrPrintout,
        BrandedModal,
        CustomTooltip,
        StoryShareTab,
        CreatomatePreview,
        CustomLoader,
        BrandingPlayerPreview,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
    },
    watch: {
        settingsModal(val) {
            if (val) {
                this.publicEvent = !this.event.private;
                this.phoneNumber = this.story.phone;
            }
        },
        onboarding(val) {
            this.windowIndex = val ? 0 : 1;
        },
        totalTributeStories(newVal) {
            this.$emit('stories-count', newVal);
        },
        tabIndex(newVal) {
            if (newVal == 'summary') {
                this.getStoryPreviewJson();
                this.getStory();
            }
        },
        render() {
            if (this.render == null) {
                this.storyTabs = [
                    { label: 'Share', value: 'share' },
                    { label: 'Stories', value: 'stories' },
                    { label: 'Summary', value: 'summary' },
                ];

                if (this.totalTributeStories > 0) {
                    this.tabIndex = 'stories';
                } else {
                    this.tabIndex = 'share';
                }

                this.storyVideoLink = '';
            } else {
                this.storyTabs = [
                    { label: 'Video', value: 'video' },
                    // { label: 'Share', value: 'share' },
                    { label: 'Stories', value: 'stories' },
                    { label: 'Summary', value: 'summary' },
                ];

                if (this.service) {
                    this.storyVideoLink = process.env.VUE_APP_BASE_URI + '/view-story/' + this.service.slug;
                    console.log(this.storyVideoLink, 'story video link');
                }
            }

            this.$emit('story-render', this.render != null);
        },
    },
    computed: {
        showPreview() {
            return this.totalSafeStories > 0 && this.previewSource != '';
        },
        disableCreateStoryRender() {
            if (this.render && (this.render.status == 0 || this.render.status == 1)) {
                return true;
            }

            return this.loading;
        },
        unsafeStories() {
            return this.totalTributeStories - this.totalSafeStories;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        displayTvQr() {
            console.log(this.story, 'story display qr');
            if (!this.story || !this.story.qrVideoUrl) {
                this.showSnackbar({ message: 'TV QR Video Unavailable', color: 'error' });
                return;
            }

            window.open(this.story.qrVideoUrl, '_blank');
        },
        initStoryVideoShareModal() {
            console.log('todo story share modal');
            if (this.$refs.storyVideoShareModal) {
                this.$refs.storyVideoShareModal.displayModal = true;
            }
        },
        deleteRenderStep1() {
            this.deleteRenderModal = true;
        },
        confirmDeleteRender(id) {
            this.loading = true;
            this.axiosInstance
                .delete(`/TributeStory/render/${id}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Render Deleted' });
                    this.render = null;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error deleting render', color: 'error' });
                })
                .finally(() => {
                    this.deleteRenderModal = false;
                    this.loading = false;
                });
        },
        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        downloadVideo(url, fileName) {
            this.showSnackbar({ message: 'Downloading' });

            this.downloadCancelToken = this.axios.CancelToken.source();

            this.downloading = true;
            this.axios
                .get(url, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'video/mp4' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobUrl;
                    anchor.download = fileName;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.showSnackbar({ message: 'Download complete' });
                })
                .catch(error => {
                    console.log(error);
                    if (this.axios.isCancel(error)) {
                        this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                    }
                })
                .finally(() => {
                    this.downloading = false;
                    this.downloadCancelToken = null;
                    this.downloadProgress = 0;
                });
        },
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to clipboard' });
        },
        renderStories(id) {
            this.loading = true;
            this.axiosInstance
                .post(`TributeStory/render-stories/${id}`)
                .then(async resp => {
                    this.render = await this.getRender(id);
                    this.tabIndex = 'video';
                })
                .catch(error => {
                    if (error.response && error.response.data == 'Please address unmoderated stories to start render') {
                        this.showSnackbar({
                            message: 'Please address unmoderated stories to start render',
                            color: 'error',
                        });
                    } else {
                        this.showSnackbar({ message: 'Error creating render', color: 'error' });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getRender(id) {
            if (!id) return;

            this.loading = true;
            return this.axiosInstance
                .get(`/TributeStory/get-render/${id}`)
                .then(resp => {
                    if (resp.data) {
                        return resp.data;
                    } else {
                        return null;
                    }
                })
                .catch(error => {
                    this.render = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveSettings() {
            if (!this.event.id) {
                this.showSnackbar({ message: 'Error saving settings', color: 'error' });
                return;
            }

            const isPrivate = !this.publicEvent;

            let data = {
                private: isPrivate,
                phone: this.phoneNumber,
            };

            this.loading = true;
            this.axiosInstance
                .put(`/Story/settings/update-settings/${this.event.id}`, data)
                .then(res => {
                    this.showSnackbar({ message: 'Settings updated' });
                    this.$emit('event-private-update', { id: this.event.id, private: isPrivate });

                    this.story.phone = data.phone;
                    this.settingsModal = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        downloadQrPdf() {
            if (this.$refs.qrStoryPrint) {
                this.$refs.qrStoryPrint.$refs.html2Pdf.generatePdf();
            } else {
                this.showSnackbar({ message: 'Unable to download PDF', color: 'error' });
            }
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `invalid email: ${email}` });
            return;
        },
        initInvite() {
            if (this.$refs.storyLinkShareModal) {
                this.$refs.storyLinkShareModal.displayModal = true;
            }
        },
        handleInviteSubmit(contacts, link) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: link,
            };

            if (this.$refs.storyLinkShareModal) {
                this.$refs.storyLinkShareModal.reset();
            }
            if (this.$refs.storyVideoShareModal) {
                this.$refs.storyVideoShareModal.reset();
            }

            this.axiosInstance
                .post(`/TributeStory/invite/${this.story.eventId}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                })
                .catch(err => {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                })
                .finally(() => {
                    this.uploadModal = false;
                });
        },
        updateStoryDeadline() {
            if (!this.story.eventId) {
                this.showSnackbar({ message: 'Invalid event id', color: 'error' });
            }

            let data = {
                deadline: this.tempDeadline,
            };

            return this.axiosInstance
                .put(`Story/settings/update-deadline/${this.story.eventId}`, data)
                .then(resp => {
                    this.story = resp.data;
                    this.settingsModal = false;
                    this.showSnackbar({ message: 'Deadline updated' });
                })
                .catch(error => {
                    console.log(error, 'deadline error');
                });
        },
        cancelDeadlineUpdate() {
            if (this.story.deadline) {
                this.$refs.deadlineCalendar.tempDate = false;
                this.tempDeadline = this.story.deadline;
            }

            this.deadlineModal = false;
        },
        async refreshProfile() {
            await this.getStory();

            this.printoutRefreshKey++;

            if (this.$refs.storyOnboarding) {
                this.$refs.storyOnboarding.stepIndex = 3;
            }
        },
        updateStoriesCount(val) {
            this.totalTributeStories = val;
        },
        storyUploaderOpen() {
            if (this.$refs.storyUploader) {
                this.$refs.storyUploader.handleOpen();
            }
        },
        profileUploadOpen() {
            if (this.$refs.storyProfileUploader) {
                this.$refs.storyProfileUploader.handleOpen();
            }
        },
        refreshTributeStories() {
            this.getStory();
        },
        getStoryPreviewJson() {
            if (!this.event.id) return;

            return this.axiosInstance
                .get(`TributeStory/get-render-json/${this.event.id}`)
                .then(resp => {
                    if (resp.data.source) {
                        this.previewSource = JSON.stringify(resp.data.source);
                    }
                    if (resp.data.modifications) {
                        this.previewMods = JSON.stringify(resp.data.modifications);
                    }
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        refreshStoryQrVideo() {
            return this.axiosInstance
                .post(`Story/refresh-qr-video/${this.event.id}`)
                .then(resp => {
                    this.story.qrVideoUrl = resp.data.qrVideoUrl ? resp.data.qrVideoUrl : '';
                    this.showSnackbar({ message: 'Video refresh started, please allow time to finish rendering' });
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        async getStory() {
            return this.axiosInstance
                .get(`Story/settings/${this.event.id}`)
                .then(resp => {
                    this.story = resp.data;
                    this.totalTributeStories = resp.data.totalTributeStories;
                    this.totalSafeStories = resp.data.totalSafeStories;
                    this.tempDeadline = resp.data.deadline;
                    this.story.qrVideoUrl = process.env.VUE_APP_BASE_URI + '/view-story-qr/' + this.service.slug;
                })
                .catch(error => {
                    if (error.response && error.response.status == 404) {
                        return null;
                    } else {
                        console.log(error, 'error');
                    }
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();

        await this.getStory();

        this.onboarding = !this.story.phone;
        this.render = await this.getRender(this.event.id);
        if (this.render) {
            this.storyTabs = [
                { label: 'Video', value: 'video' },
                { label: 'Share', value: 'share' },
                { label: 'Stories', value: 'stories' },
                { label: 'Summary', value: 'summary' },
            ];

            this.tabIndex = 'video';
        } else {
            this.storyTabs = [
                { label: 'Share', value: 'share' },
                { label: 'Stories', value: 'stories' },
                { label: 'Summary', value: 'summary' },
            ];

            if (this.totalTributeStories > 0) {
                this.tabIndex = 'stories';
            }
        }
        this.getStoryPreviewJson();

        this.mounted = true;

        this.loading = false;
    },
    sockets: {
        async NotifyStoryRender(data) {
            if (this.event.id == data.eventId) {
                this.render = await this.getRender(this.event.id);
                this.reloadCount++;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.ms-orange {
    color: #ff530d;
}
</style>
