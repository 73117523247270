<template>
    <div>
        <v-data-table
            :loading="loading"
            loading-text="Getting Live Streams..."
            :headers="headers"
            :items="services"
            class="services-table"
            :style="{ paddingTop: '8px' }"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50, 100],
            }"
            :server-items-length="totalServices"
            :options.sync="options"
            :must-sort="true"
            @click.row="goToEvent"
            hide-default-footer
        >
            <template v-slot:header.eventStatus="{ header }">
                <v-menu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :disabled="loading"
                    @input="handleEventStatusMenuToggle"
                >
                    <template v-slot:activator="{ on: menu, attrs }">
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <span v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    {{ header.text }} ({{ statusSelected.length }})
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>
                            <span>Select Filters</span>
                        </v-tooltip> -->
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }} ({{ statusSelected.length }})
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 200px">
                        <div class="text-center">
                            <v-btn style="margin-bottom: 0.6rem" small width="90%" @click="toggleAllEvents"
                                >{{ allStatusSelected ? 'Deselect' : 'Select' }} All</v-btn
                            >
                        </div>
                        <v-list-item v-for="(item, index) in eventStates" :key="index" style="height: 20px">
                            <v-checkbox v-model="statusSelected" :label="item.label" :value="item.value"></v-checkbox>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:header.eventIssue="{ header }">
                <v-menu
                    :close-on-content-click="false"
                    :offset-y="true"
                    :disabled="loading"
                    @input="handleEventIssueMenuToggle"
                >
                    <template v-slot:activator="{ on: menu, attrs }">
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <span v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    {{ header.text }} ({{ issuesSelected.length }})
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>
                            <span>Select Filters</span>
                        </v-tooltip> -->
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span v-bind="attrs" v-on="{ ...menu }">
                                    {{ header.text }} ({{ issuesSelected.length }})
                                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Select Filters</span>
                            </template>
                        </custom-tooltip>
                    </template>
                    <v-list style="width: 250px">
                        <div class="text-center">
                            <v-btn style="margin-bottom: 0.6rem" @click="toggleAllIssues" width="90%" small
                                >{{ allIssuesSelected ? 'Deselect' : 'Select' }} All</v-btn
                            >
                        </div>
                        <div v-for="(item, index) in issues" :key="index">
                            <v-list-item v-if="item.title !== '_DIVIDER_'" style="height: 14px">
                                <div max-width="250px">
                                    <div style="display: flex; align-items: center; gap: 0.5rem">
                                        <v-checkbox v-model="issuesSelected" :value="item.value" color="primary" />
                                        <!-- <v-tooltip bottom max-width="250px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"
                                                    ><font-awesome-icon
                                                        :style="{ color: item.color, margin: '0 10px 0 0' }"
                                                        :icon="item.icon"
                                                    />{{ item.text }}
                                                </span>
                                            </template>
                                            <span>{{ item.tooltip }}</span>
                                        </v-tooltip> -->

                                        <custom-tooltip :tooltipProps="{ bottom: true, maxWidth: '250px' }">
                                            <template v-slot:activator>
                                                <span
                                                    ><font-awesome-icon
                                                        :style="{ color: item.color, margin: '0 10px 0 0' }"
                                                        :icon="item.icon"
                                                    />{{ item.text }}
                                                </span>
                                            </template>

                                            <template v-slot:content>
                                                <span>{{ item.tooltip }}</span>
                                            </template>
                                        </custom-tooltip>
                                    </div>
                                </div>
                            </v-list-item>
                            <div v-else style="margin: 0; height: 1px; width: 100%; background: lightgray" />
                        </div>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:item.eventDateTime="{ item }">
                <div class="text-center" style="font-weight: 700">
                    {{ $moment.utc(item.eventDateTime).local().format('h:mm A') }}
                </div>
                <div class="text-center">
                    {{ $moment.utc(item.eventDateTime).local().format('MM/DD/YYYY') }}
                </div>
                <div v-if="item.doNotWatch" class="text-center">
                    <v-chip x-small dark color="error">Do Not Watch</v-chip>
                </div>

                <div
                    v-if="item.eventType != 3 && (item.streamingProvider == 3 || item.streamingProvider == 5)"
                    class="text-center"
                >
                    <v-chip x-small dark>{{ streamingProviders[item.streamingProvider].label }}</v-chip>
                </div>
            </template>
            <template v-slot:item.eventTitle="{ item }">
                <v-col @click.stop>
                    <v-row>
                        <a :href="`/services/${item.serviceSlug}/${item.eventId}`">{{ item.eventTitle }}</a>
                    </v-row>
                </v-col>
            </template>

            <template v-slot:item.funeralHomeName="{ item }">
                <span @click.stop>
                    <div>
                        <div class="d-flex">
                            <v-chip class="mr-2" label dark x-small :color="totalEventColor(item.totalEvents)">
                                <font-awesome-icon v-if="item.totalEvents > 999" class="mr-2" icon="fa-solid fa-bolt" />
                                {{ totalEventLabel(item.totalEvents) }}
                            </v-chip>
                            <div>
                                <custom-tooltip :tooltipProps="{ top: true }">
                                    <template v-slot:activator>
                                        <div class="d-flex align-center" v-if="item.userPhoneNo">
                                            <v-chip
                                                :color="phoneTypes[item.userPhoneType].color"
                                                dark
                                                class="mr-2"
                                                label
                                                x-small
                                                >{{ phoneTypes[item.userPhoneType].label }}</v-chip
                                            >
                                            <font-awesome-icon
                                                :icon="getPhopneTypeIcon(item.userPhoneType)"
                                                style="font-size: 1rem"
                                                @click="copyUserNo(item.userPhoneNo)"
                                            ></font-awesome-icon>
                                        </div>
                                    </template>

                                    <template v-slot:content>
                                        <span>{{ item.userPhoneNo }}</span>
                                    </template>
                                </custom-tooltip>
                            </div>
                        </div>
                        <div>
                            <a :href="`/services/homes/${item.funeralHomeId}`">{{ item.funeralHomeName }} </a>
                        </div>
                    </div>
                </span>
            </template>
            <template v-slot:item.enum="{ item }">
                {{ item.eventStatus }}
            </template>

            <template v-if="$auth.role.includes('SuperAdmin')" v-slot:item.eventStatus="{ item }">
                <div class="chip-container">
                    <span
                        :style="{ '--background-color': eventStates[item.eventStatus].color }"
                        class="chip"
                        :class="{ 'chip--live': item.eventStatus === 2 }"
                    >
                        {{ eventStates[item.eventStatus].label }}
                    </span>
                </div>
            </template>

            <template v-if="$auth.role.includes('SuperAdmin')" v-slot:item.streamFailureEmail="{ item }">
                <custom-tooltip v-if="item.userLandline || !item.userPhoneNo" :tooltipProps="{ top: true }">
                    <template v-slot:activator>
                        <font-awesome-icon
                            style="font-size: 20px; color: #ff9800"
                            icon="fa-solid fa-bell-exclamation"
                            @click="initStreamFailureModal(item)"
                        />
                    </template>

                    <template v-slot:content>
                        <span>Send Failure Email</span>
                    </template>
                </custom-tooltip>
            </template>

            <template v-slot:item.started="{ item }">
                <div style="display: flex; justify-content: start">
                    <span
                        style="font-size: 25px; color: green; padding-left: 8px"
                        v-if="item.started || item.status === 7"
                        class="mdi mdi-check-circle-outline"
                    ></span>

                    <span v-else>
                        <span
                            v-if="new Date().getTime() < new Date(`${item.eventDateTime}Z`).getTime() - 600000"
                            style="font-size: 25px; color: gray; padding-left: 8px"
                            class="mdi mdi-minus-circle-outline"
                        />
                        <div
                            class="blob-stream-sheet"
                            v-else-if="
                                new Date().getTime() >= new Date(`${item.eventDateTime}Z`).getTime() &&
                                item.eventStatus !== 7 &&
                                item.eventIssue === null
                            "
                        >
                            <span style="font-size: 20px; color: white" class="mdi mdi-exclamation" />
                        </div>

                        <span
                            v-else-if="new Date().getTime() > new Date(`${item.eventDateTime}Z`).getTime() - 300000"
                            style="font-size: 25px; color: red; padding-left: 8px"
                            class="mdi mdi-alert-decagram"
                        />

                        <span
                            v-else-if="new Date().getTime() > new Date(`${item.eventDateTime}Z`).getTime() - 600000"
                            style="font-size: 25px; color: orange; padding-left: 8px"
                            class="mdi mdi-alert"
                        />

                        <span style="font-size: 25px; color: orange; padding-left: 8px" v-else class="mdi mdi-alert" />
                    </span>
                </div>
            </template>

            <template v-slot:item.eventIssue="{ item }">
                <div v-if="$auth.role.includes('SuperAdmin')">
                    <v-select
                        dense
                        class="selectIssue"
                        v-model="item.eventIssue"
                        style="border-radius: 10px"
                        return-object
                        :items="issues.filter(issue => issue.title !== '_DIVIDER_')"
                        item-text="title"
                        item-value="value"
                        label=""
                        @input="updateStatusAndIssue(item, item.eventIssue)"
                        @click.stop
                        chips
                        small
                    >
                        <template
                            v-slot:selection="{ item, index }"
                            @input="updateStatusAndIssue(item.eventId, item.eventIssue)"
                        >
                            <v-chip text-color="white" :color="item.color">{{ item.text }}</v-chip>
                        </template>
                        <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <span>{{ item.descr }}</span>
                                            <v-spacer></v-spacer>
                                            <v-chip text-color="white" :color="item.color">{{ item.text }}</v-chip>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>

                <div v-else>
                    <v-chip
                        v-if="item.eventIssue >= 0 && item.eventIssue !== null"
                        dark
                        :color="getIssueColor(item.eventIssue)"
                        >{{ getIssueText(item.eventIssue) }}</v-chip
                    >
                </div>
            </template>

            <template v-slot:item.wowza="{ item }">
                <span v-if="item.providerId" @click.stop @click="openWowzaTab(item.providerId)">
                    <font-awesome-icon style="font-size: 1rem" icon="fa-regular fa-arrow-up-right-from-square" />
                </span>
            </template>
            <template v-slot:item.search="{ item }">
                <span @click.stop @click="openSearchTab(item)">
                    <font-awesome-icon style="font-size: 1rem" icon="fa-regular fa-magnifying-glass" />
                </span>
            </template>

            <template
                v-if="
                    $auth.role.includes('SuperAdmin') ||
                    $auth.role.includes('Owner') ||
                    $auth.role.includes('RegionalManager')
                "
                v-slot:item.eventNotes="{ item }"
            >
                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <span
                                @click.stop
                                @click="handleNotesClick(item)"
                                style="font-size: 20px"
                                class="mdi mdi-message-text-outline"
                            ></span>
                        </span>
                    </template>
                    <span>Notes</span>
                </v-tooltip> -->
                <custom-tooltip :tooltipProps="{ top: true }">
                    <template v-slot:activator>
                        <span
                            @click.stop
                            @click="handleNotesClick(item)"
                            style="font-size: 20px"
                            class="mdi mdi-message-text-outline"
                        ></span>
                    </template>

                    <template v-slot:content>
                        <span>Notes</span>
                    </template>
                </custom-tooltip>
                <template>
                    <v-dialog v-model="notes.show" :retain-focus="false" max-width="550">
                        <v-card>
                            <v-card-title class="headline" style="border-bottom: 1px solid lightgray">
                                <small>{{ notes.funeralHomeName }} | {{ notes.eventTitle }}</small></v-card-title
                            >

                            <v-card-text v-if="$auth.role.includes('SuperAdmin')" style="padding-top: 20px">
                                <v-textarea
                                    filled
                                    :placeholder="notemessage ? notemessage : 'Add notes'"
                                    v-model="notemessage"
                                ></v-textarea>
                                <v-btn @click="updateNotes(notes.eventId)">Submit Note</v-btn>
                            </v-card-text>
                            <v-card-text v-else style="padding-top: 20px">
                                <p>{{ notemessage ? notemessage : 'This event does not have any notes' }}</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn color="gdarken-1" text @click="notes.show = false"> Exit </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </template>

            <template v-slot:footer="{ props }">
                <div
                    class="d-flex align-center justify-space-between flex-wrap-reverse"
                    style="border-top: 1px solid #d8d8d8"
                >
                    <div style="color: #4b4b4b" class="d-flex align-center pt-4">
                        <v-btn ref="reverseDayBtn" @click="reverseDay" fab text small>
                            <font-awesome-icon
                                icon="fa-solid fa-arrow-left"
                                style="font-size: 1.3rem"
                            ></font-awesome-icon>
                        </v-btn>

                        <h3 class="mx-2 my-0">{{ customizedTitle }}</h3>

                        <v-btn ref="advanceDayBtn" @click="advanceDay" fab small text>
                            <font-awesome-icon
                                icon="fa-solid fa-arrow-right"
                                style="font-size: 1.3rem"
                            ></font-awesome-icon>
                        </v-btn>
                    </div>

                    <v-data-footer style="border: none" :options.sync="options" v-bind="props"></v-data-footer>
                </div>
            </template>
        </v-data-table>

        <v-dialog
            max-width="450px"
            v-if="selectedStream"
            @click:outside="cancelStreamFailureEmail"
            v-model="streamFailureEmailModal"
        >
            <v-card :loading="sendingEmail">
                <v-alert rounded="0" v-if="selectedStream.emailNotified" type="warning">Already Notified</v-alert>
                <v-card-title>Send Stream Failure Email?</v-card-title>
                <v-window v-model="streamFailureEmailWindows">
                    <v-window-item :value="1">
                        <v-card-text
                            >Are you sure you would like to send a Stream Failure Email for
                            <strong>{{ selectedStream.eventTitle }}</strong
                            >?</v-card-text
                        >
                    </v-window-item>
                    <v-window-item :value="2">
                        <div class="pa-3">
                            <v-row>
                                <v-col class="my-1 py-1" cols="12">
                                    <v-select
                                        :items="users"
                                        return-object
                                        v-model="streamContactUser"
                                        outlined
                                        item-text="email"
                                        label="User"
                                    ></v-select>
                                </v-col>

                                <v-col class="my-1 py-1" sm="6" cols="12">
                                    <v-select
                                        outlined
                                        :items="streamFailureReasons"
                                        v-model="failureEmailReason"
                                        item-text="label"
                                        item-value="value"
                                        label="Reason"
                                    ></v-select>
                                </v-col>

                                <v-col class="my-1 py-1" sm="6" cols="12">
                                    <!-- <v-text-field outlined readonly v-model="phoneNumberStatus"></v-text-field> -->
                                    <v-select
                                        outlined
                                        :items="phoneStatusPresets"
                                        v-model="phoneNumberStatus"
                                        item-text="label"
                                        item-value="value"
                                        label="Phone Status"
                                    ></v-select>
                                </v-col>

                                <v-col class="my-1 py-1" cols="12">
                                    <v-textarea
                                        height="300px"
                                        outlined
                                        v-model="failureEmailMessage"
                                        label="Message"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </div>
                    </v-window-item>
                </v-window>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="handleSteamFailureEmailBackClick" depressed>{{
                        streamFailureEmailWindows > 1 ? 'Back' : 'Cancel'
                    }}</v-btn>
                    <v-btn
                        @click="handleSteamFailureEmailNextClick"
                        :color="selectedStream.emailNotified ? 'warning' : 'primary'"
                        depressed
                        >{{ streamFailureEmailWindows == 2 ? 'Confirm' : 'Next' }}</v-btn
                    >
                    <!-- <v-btn @click="submitStreamFailureEmail" depressed color="error">Confirm</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="400px" v-model="duplicateNotificationWarning">
            <v-card>
                <v-card-title> Already Notified </v-card-title>
                <v-card-text> An alert has already been sent for this event. Please confirm to continue. </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="duplicateNotificationWarning = false">Cancel</v-btn>
                    <v-btn depressed color="warning" @click="submitStreamFailureEmail">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { serviceStates, eventStates, streamingProviders, phoneTypes } from '@/constants';
import debounce from '@/utilities/debounce.js';
import { getLocalSettings, setLocalSettings } from '@/utilities/general';
import DatePicker from '@/components/ui/DatePicker.vue';
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    name: 'ServicesTable',
    components: {
        DatePicker,
        CustomTooltip,
    },
    props: ['date', 'customized', 'mysearch', 'streamsEndDate', 'customizedTitle'],
    data() {
        return {
            phoneTypes,
            streamingProviders,
            statusSelected: [0, 1, 2, 3, 4, 5, 6, 7],
            issuesSelected: [0, 1, 2, 3, 4, 5, 6, 7, 8, ''],
            statusSelectedParamString: '',
            issueSelectedParamString: '',
            statusFilterOpen: false,
            dateSelected: false,
            notemessage: '',
            tempDate: '08/12/1990',
            users: [],
            loadingUsers: false,
            streamFailureEmailWindows: 1,
            failureEmailMessage: '',
            failureEmailReason: null,
            streamContactUser: null,
            duplicateNotificationWarning: false,
            phoneNumberStatus: null,
            streamFailureReasons: [
                { label: "Didn't Stream", value: 0, messagePreset: '' },
                { label: 'Stream Issue', value: 1, messagePreset: '' },
            ],
            phoneStatusPresets: [
                { label: 'Landline', value: 0, message: '' },
                { label: 'No Phone', value: 1, message: '' },
            ],
            issues: [
                {
                    title: 'Success',
                    value: 4,
                    text: 'Success',
                    color: '#3e8f34',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip: 'The event was streamed successfully.',
                    icon: 'fa-regular fa-check-circle',
                },
                {
                    title: 'Record',
                    value: 7,
                    text: 'Record',
                    color: '#4cb050',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip: 'The funeral home decided to record the service instead of live streaming',
                    icon: 'fa-regular fa-video',
                },
                {
                    title: '_DIVIDER_',
                },
                {
                    title: 'Connection',
                    value: 6,
                    text: 'Connection',
                    color: '#f44336',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip:
                        'The stream was not successful because the network was not strong enough to support a live stream.',
                    icon: 'fa-regular fa-wifi-exclamation',
                },
                {
                    title: 'Audio',
                    value: 8,
                    text: 'Audio',
                    color: '#f44336',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip:
                        'The stream was not successful because the network was not strong enough to support a live stream.',
                    icon: 'fa-regular fa-volume',
                },
                {
                    title: 'Did Not Stream',
                    value: 5,
                    text: 'Did Not Stream',
                    color: '#2196f2',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip: 'The funeral home decided not to stream this service.',
                    icon: 'fa-regular fa-circle-x',
                },
                {
                    title: 'Education',
                    value: 0,
                    text: 'Education',
                    color: '#ff9800',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip: 'The funeral director did not understand how to use MemoryShare properly.',
                    icon: 'fa-regular fa-graduation-cap',
                },
                {
                    title: '_DIVIDER_',
                },
                {
                    title: 'Mobile App',
                    value: 1,
                    text: 'Mobile App',
                    color: '#f44336',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip:
                        'The MemoryShare IOS app had a software bug that prevented this service from successfully streaming.',
                    icon: 'fa-regular fa-mobile',
                },
                {
                    title: 'MemoryShare',
                    value: 3,
                    text: 'MemoryShare',
                    color: 'orange',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip:
                        'The MemoryShare software had a bug or downtime that prevented this service from streaming.',
                    icon: 'fa-regular fa-cloud',
                },
                {
                    title: 'Cloud',
                    value: 2,
                    text: 'Cloud',
                    color: 'orange',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip:
                        'MemoryShare relies on both Azure and AWS to live stream, an issue with one of these providers caused this stream to fail.',
                    icon: 'fa-regular fa-cloud',
                },
                {
                    title: '_DIVIDER_',
                },

                {
                    title: 'Blank',
                    value: '',
                    text: 'Blank',
                    color: 'black',
                    virtual: true,
                    hidden: false,
                    locked: false,
                    tooltip: 'A MemoryShare representative has not filled in the information for this stream yet.',
                    icon: 'fa-regular fa-shield',
                },
            ],
            loading: false,
            title: '',
            token: '',
            search: '',
            headers: [],
            services: [],
            totalServices: null,
            streamFailureEmailModal: false,
            sendingEmail: false,
            selectedStream: null,
            deleteModal: {
                show: false,
                message: '',
                serviceId: null,
            },
            notes: {
                funeralHomeName: '',
                eventTitle: '',
                show: false,
                message: '',
                serviceId: null,
                eventId: '',
            },
            serviceStates,
            options: {},
            tableLoading: false,
            eventStates,
            currentOffset: null,
            filterMenuOpen: false,
        };
    },
    watch: {
        dateSelected() {
            this.customized = this.dateSelected;
            this.title = 'Customized';
            return this.dateSelected;
        },
        date() {
            this.loading = true;
            this.debouncedGetServices();

            if (!this.tableLoading) {
                setLocalSettings('serviceTable', {
                    search: this.search,
                    page: this.options.page,
                    pageSize: this.options.itemsPerPage,
                });
                this.options = {
                    ...this.options,
                    page: this.options.page,
                };
            }
        },
        failureEmailReason() {
            if (this.failureEmailReason != null) {
                this.failureEmailMessage = this.streamFailureReasons[this.failureEmailReason].messagePreset;
            }

            if (this.failureEmailReason != null && this.phoneNumberStatus != null) {
                this.failureEmailMessage =
                    this.streamFailureReasons[this.failureEmailReason].messagePreset +
                    '\n\n' +
                    this.phoneStatusPresets[this.phoneNumberStatus].message;
            }
        },
        phoneNumberStatus(val) {
            if (this.failureEmailReason != null && this.phoneNumberStatus != null) {
                this.failureEmailMessage =
                    this.streamFailureReasons[this.failureEmailReason].messagePreset +
                    '\n\n' +
                    this.phoneStatusPresets[this.phoneNumberStatus].message;
            }
        },
        streamContactUser(val) {
            if (val) {
                if (val?.landLine) {
                    this.phoneNumberStatus = 0;
                } else {
                    this.phoneNumberStatus = 1;
                }

                this.phoneStatusPresets[0].message = `We attempted to send a text message to ${val.phoneNo}, but it appears to be a landline number. Please update your contact information with a mobile number. If ${val.phoneNo} is indeed a cell phone, kindly respond to this email to confirm.`;
                this.phoneStatusPresets[1].message = `We were unable to text you because ${
                    val.firstName ? val.firstName : 'the user'
                } does not have a contact number.`;
            }
        },
        options: {
            handler() {
                if (this.token && this.axiosInstance) {
                    this.getServices();
                }
                this.resetScroll();
            },
            deep: true,
        },
        streamsEndDate() {
            this.loading = true;
            this.debouncedGetServices();

            if (!this.tableLoading) {
                setLocalSettings('serviceTable', {
                    search: this.search,
                    page: this.options.page,
                    pageSize: this.options.itemsPerPage,
                });
                this.options = {
                    ...this.options,
                    page: this.options.page,
                };
            }
        },

        mysearch() {
            this.loading = true;
            this.debouncedGetServices();

            if (!this.tableLoading) {
                setLocalSettings('serviceTable', {
                    search: this.search,
                    page: this.options.page,
                    pageSize: this.options.itemsPerPage,
                });
                this.options = {
                    ...this.options,
                    page: this.options.page,
                };
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        advanceDay() {
            if (this.$refs.advanceDayBtn) {
                this.$refs.advanceDayBtn.$el.blur();
            }
            this.$emit('advance-day');
        },
        reverseDay() {
            if (this.$refs.reverseDayBtn) {
                this.$refs.reverseDayBtn.$el.blur();
            }
            this.$emit('reverse-day');
        },
        getPhopneTypeIcon(phoneTypeEnum) {
            let icon = '';

            switch (phoneTypeEnum) {
                case 1:
                case 4:
                    icon = 'fa-solid fa-mobile';
                    break;
                case 2:
                case 3:
                    icon = 'fa-solid fa-phone-rotary';
                    break;
                default:
                    icon = 'fa-solid fa-circle-question';
            }

            return icon;
        },
        getUsers(id) {
            this.loadingUsers = true;
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/users?funeralHomeId=${id}&quick=true`)
                .then(response => {
                    this.users = response.data;

                    this.loadingUsers = false;
                })
                .catch(error => {
                    console.log('error', error);
                    this.loadingUsers = false;
                });
        },
        handleSteamFailureEmailBackClick() {
            if (this.streamFailureEmailWindows == 2) {
                this.streamFailureEmailWindows = 1;
            } else {
                this.cancelStreamFailureEmail();
            }
        },
        handleSteamFailureEmailNextClick() {
            if (this.streamFailureEmailWindows !== 2) {
                this.streamFailureEmailWindows = 2;
                return;
            }

            if (this.selectedStream.emailNotified) {
                this.duplicateNotificationWarning = true;
                return;
            }

            this.submitStreamFailureEmail();
        },
        submitStreamFailureEmail() {
            if (!this.selectedStream?.eventId) {
                this.showSnackbar({ message: 'Selected stream not found', color: 'error' });
                return;
            }

            if (!this.streamContactUser?.email) {
                this.showSnackbar({ message: 'Invalid Email', color: 'error' });
                return;
            }

            if (this.failureEmailReason == null) {
                this.showSnackbar({ message: 'Invalid Reason', color: 'error' });
                return;
            }

            if (this.failureEmailMessage == null) {
                this.showSnackbar({ message: 'Invalid Message', color: 'error' });
                return;
            }

            let contactEmail = this.streamContactUser.email;
            let message = this.failureEmailMessage.replace(/\n/g, '<br>');

            let data = {
                reason: this.failureEmailReason,
                message: message,
                email: contactEmail,
            };

            this.sendingEmail = true;
            this.axiosInstance
                .post(`/admin-tools/send-stream-failure-email/${this.selectedStream.eventId}`, data)
                .then(response => {
                    this.showSnackbar({ message: 'Email sent!' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error sending stream failure email.', color: 'error' });
                })
                .finally(() => {
                    this.sendingEmail = false;
                    this.cancelStreamFailureEmail();
                });
        },
        async initStreamFailureModal(item) {
            this.selectedStream = item;
            this.streamFailureEmailModal = true;

            await this.getUsers(item.funeralHomeId);

            let noStreamPreset = `Hello, this is the MemoryShare Team. We would like to let you know that we don’t see any video coming through on the event “${item.eventTitle}”. Please ensure that you have a good network connection and that the record button has been pressed.`;
            let streamIssuePreset = `Hello, this is the MemoryShare Team. We want to inform you that the stream quality for the event "${item.eventTitle}" is currently unstable. To ensure a smooth experience, we recommend uploading the stream after its conclusion.`;

            this.streamFailureReasons[0].messagePreset = noStreamPreset;
            this.streamFailureReasons[1].messagePreset = streamIssuePreset;

            if (this.selectedStream.userId > 0) {
                this.streamContactUser = this.users.find(x => x.id == this.selectedStream.userId);

                if (this.streamContactUser) {
                    this.phoneStatusPresets[0].message = `We attempted to send a text message to ${this.streamContactUser.phoneNo}, but it appears to be a landline number. Please update your contact information with a mobile number. If ${this.streamContactUser.firstName} is indeed a cell phone, kindly respond to this email to confirm.`;
                    this.phoneStatusPresets[1].message = `We were unable to text you because ${this.streamContactUser.name} does not have a contact number.`;
                }
            }
        },
        cancelStreamFailureEmail() {
            this.duplicateNotificationWarning = false;
            this.streamFailureEmailModal = false;
            this.failureEmailMessage = '';
            this.failureEmailReason = null;
            this.streamContactUser = null;

            this.streamFailureReasons[0].messagePreset = '';
            this.streamFailureReasons[1].messagePreset = '';

            setTimeout(() => {
                this.streamFailureEmailWindows = 1;
                this.selectedStream = null;
            }, 200);
        },
        copyUserNo(num) {
            try {
                navigator.clipboard.writeText(num.replaceAll('.', ''));
                this.showSnackbar({ message: 'Copied to clipboard' });
            } catch (err) {
                console.log(err, 'error');
                this.showSnackbar({ message: 'error copying to clipboard', color: 'red' });
            }
        },
        async openSearchTab(item) {
            const {
                data: { funeralHome: fh, settings },
            } = await this.axiosInstance.get('/FuneralHomes/' + item.funeralHomeId);

            if (fh.website) {
                const query = `[site:${fh.website} ${item.serviceFirstName} ${item.serviceLastName} obituary]`;
                const searchURL = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
                window.open(searchURL, '_blank');
            } else {
                const query = `${fh.name} ${fh.city} ${fh.state} ${item.serviceFirstName} ${item.serviceLastName} obituary`;
                const searchURL = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
                window.open(searchURL, '_blank');
            }
        },
        // totalEventIcon(val) {
        //     if (val == 0) {
        //         return 'fa-solid fa-triangle-exclamation';
        //     } else if (val < 10) {
        //         return 'fa-solid fa-magnifying-glass';
        //     } else if (val < 50) {
        //         return 'fa-solid fa-binoculars';
        //     } else if (val < 100) {
        //         return 'fa-solid fa-thumbs-up';
        //     } else if (val < 500) {
        //         return 'fa-solid fa-badge-check';
        //     } else if (val < 1000) {
        //         return 'fa-solid fa-bolt';
        //     } else if (val >= 1000) {
        //         return 'fa-solid fa-star';
        //     }
        // },
        totalEventColor(val) {
            if (val == 0) {
                return 'error';
            } else if (val < 10) {
                return 'orange';
            } else if (val < 50) {
                return 'purple';
            } else if (val < 100) {
                return 'indigo';
            } else if (val < 500) {
                return 'teal';
            } else if (val < 1000) {
                return 'blue';
            } else if (val >= 1000) {
                return ' #00C853';
            }
        },
        totalEventLabel(val) {
            if (val < 50) {
                return val;
            } else if (val < 100) {
                return '50 +';
            } else if (val < 500) {
                return '100 +';
            } else if (val < 1000) {
                return ' 500 +';
            } else if (val >= 1000) {
                return '1000 +';
            }
        },
        toggleAllEvents() {
            if (this.allStatusSelected) {
                this.statusSelected = [];
            } else {
                this.eventStates.forEach(event => {
                    if (!this.statusSelected.includes(event.value)) {
                        this.statusSelected.push(event.value);
                    }
                });
            }
        },
        toggleAllIssues() {
            if (this.allIssuesSelected) {
                this.issuesSelected = [];
            } else {
                this.issues.forEach(issue => {
                    if (issue.value >= 0 && !this.issuesSelected.includes(issue.value)) {
                        this.issuesSelected.push(issue.value);
                    }
                });
            }
        },
        getIssueColor: function (eventIssue) {
            const issue = this.issues.find(issue => issue.value === eventIssue);

            if (issue) {
                return issue.color;
            } else return '';
        },
        getIssueText: function (eventIssue) {
            const issue = this.issues.find(issue => issue.value === eventIssue);

            if (issue) {
                return issue.text;
            } else return '';
        },
        handleEventStatusMenuToggle: function () {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.statusSelected.length === 0) {
                    this.services = [];
                    return (this.statusSelectedParamString = '');
                }

                let string = '';
                this.statusSelected.forEach(status => (string += `&status=${status}`));
                this.statusSelectedParamString = string;
                this.getServices();

                this.filterMenuOpen = false;
            }
        },
        handleEventIssueMenuToggle: function () {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.issuesSelected.length === 0) {
                    this.services = [];
                    return (this.issueSelectedParamString = '');
                }

                let string = '';
                this.issuesSelected.forEach(issue => {
                    let issueValue = this.issues.find(issueItem => issueItem?.value === issue);
                    string += `&issue=${issueValue.value}`;
                });

                this.issueSelectedParamString = string;
                this.getServices();
                this.filterMenuOpen = false;
            }
        },
        handleNotesClick(item) {
            this.notes.show = true;
            this.notes.funeralHomeName = item.funeralHomeName;
            this.notes.eventTitle = item.eventTitle;
            this.notemessage = item.eventNotes;
            this.notes.eventId = item.eventId;
        },
        resetScroll() {
            var main = document.querySelector('#scroll');
            main.scrollTop = 0;
        },
        openWowzaTab(id) {
            window.open('https://cloud.wowza.com/en/thgvyjtb/manage/live_streams/' + id);
        },
        checkOffset() {
            var offset = moment(new Date()).utcOffset() / 60;
            this.currentOffset = Math.abs(offset);
        },
        getDate() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;
            return today;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        playService(slug) {
            this.$router.push({ path: `/services/view/${slug}` });
        },
        goToEvent(item) {
            this.$router.push({ path: `/services/${item.serviceSlug}/${item.eventId}` });
        },
        newTabEvent(e, item) {
            let dssEvent = item.item;
            let route = this.$router.resolve({ path: `/services/${dssEvent.serviceSlug}/${dssEvent.eventId}` });
            window.open(route.href);
        },
        viewAnalytics(slug) {
            this.$router.push({ path: `/analytics/${slug}` });
        },
        openDeleteModal(item) {
            this.deleteModal.message = `Are you sure you want to delete the service for ${item.name}?`;
            this.deleteModal.serviceId = item.id;
            this.deleteModal.show = true;
        },
        deleteService(id) {
            this.loading = true;
            this.axiosInstance
                .delete(`/Services/${id}`)
                .then(result => {
                    this.deleteModal.show = false;
                    const { name } = this.services.find(service => service.id === id);
                    const message = `Service for ${name} was deleted`;
                    this.showSnackbar({ message });
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.Message,
                    });
                })
                .finally(() => {
                    this.getServices();
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'eventStatus':
                    return 'Status';
                case 'funeralHomeName':
                    return 'Home';
                case 'eventDateTime':
                    return 'Time';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },

        updateNotes(id) {
            // event id is passed through
            this.notes.show = false;
            this.loading = true;
            let data = {
                notes: this.notemessage,
            };
            // post route is created and triggered
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/admin-tools/update-notes/${id}`, data)
                .then(result => {
                    const message = `Note was updated successfully!`;
                    this.showSnackbar({ message });
                })
                .catch(error => {
                    const { name } = this.services.find(service => service.id === id);
                    const message = `Error updating note for this event. Please try again.`;
                    this.showSnackbar({ message });
                })
                .finally(() => {
                    this.getServices();
                });
        },
        updateStatusAndIssue(item, eventIssue) {
            this.loading = true;

            let data = {
                issue: eventIssue.value,
                eventStatus: item.eventStatus,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/admin-tools/update-status/${item.eventId}`, data)
                .then(result => {
                    const message = `Event Issue was updated successfully!`;
                    this.showSnackbar({ message });
                })
                .catch(error => {
                    const message = `Error updating Event Issue. Please try again.`;
                    this.showSnackbar({ message });
                })
                .finally(() => {
                    this.getServices();
                });
        },
        getQueryParams() {
            //start and stop times are utc but without 'z' to match backend
            // set start date to midnight
            var start = moment(this.date).hours(0).minutes(0)._i;

            //set end date to 23:59
            var end = moment(this.streamsEndDate).hours(23).minutes(59)._i;

            let string = `offset=${this.currentOffset}&sortBy=EventDateTime`;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            // string += `&startDate=${this.startDate ? new Date(this.startDate).toISOString() : this.date}`;
            // string += `&endDate=${this.endDate ? new Date(this.endDate).toISOString() : this.date}`;
            string += `&startDate=${start}`;
            string += `&endDate=${end}`;
            string += `&sortAsc=${sortBy ? !sortDesc[0] : true}`;
            string += `&pageSize=${itemsPerPage}`;
            string += `&pageNumber=${page ? page - 1 : 1}`;

            string += this.statusSelectedParamString;
            string += this.issueSelectedParamString;

            return string;
        },

        async getServices() {
            this.loading = true;
            const params = this.getQueryParams();
            if (
                this.$auth.role.includes('SuperAdmin') ||
                this.$auth.role.includes('Owner') ||
                this.$auth.role.includes('RegionalManager')
            ) {
                if (this.date !== new Date().toISOString()) {
                    try {
                        const response = await this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + `/admin-tools/daily-stream-sheet?${params}`, {
                                params: { search: this.mysearch || '' },
                            });

                        if (this.$auth.role.includes('SuperAdmin')) {
                            this.headers = [
                                { text: 'Home', value: 'funeralHomeName', width: '16%', sortable: true },
                                { text: 'Event', value: 'eventTitle', width: '15%', sortable: true },
                                { text: 'Time', value: 'eventDateTime', width: '10%', align: 'center', sortable: true },
                                { text: 'Started', value: 'started', width: '10%', sortable: true },
                                { text: 'Status', value: 'eventStatus', width: '14%', sortable: false },
                                { text: 'Success or Issues', value: 'eventIssue', width: '15%', sortable: false },
                                { text: 'Notes', value: 'eventNotes', width: '15%', align: 'center' },
                                { text: 'Wowza', value: 'wowza', width: '15%', align: 'center', sortable: false },
                                { text: 'Search', value: 'search', align: 'center', sortable: false },
                                { text: '', value: 'streamFailureEmail', sortable: false },
                            ];

                            this.totalServices = response.data.dailyLiveStream.total;

                            this.services = response.data.dailyLiveStream.data.map(service => {
                                return {
                                    serviceSlug: service.serviceSlug,
                                    eventId: service.eventId,
                                    funeralHomeId: service.funeralHomeId,
                                    eventDateTime: service.eventDateTime,
                                    funeralHomeName: service.funeralHomeName,
                                    eventTitle: service.eventTitle,
                                    created: service.createdDate,
                                    createdBy: service.creatorEmail,
                                    started: service.started,
                                    eventStatus: service.eventStatus,
                                    eventIssue: service.eventIssue,
                                    eventNotes: service.eventNotes,
                                    providerId: service.providerId,
                                    totalEvents: service.totalEvents,
                                    doNotWatch: service.doNotWatch,
                                    serviceFirstName: service.serviceFirstName,
                                    serviceLastName: service.serviceLastName,
                                    userId: service.userId,
                                    userLandline: service.userLandline,
                                    userPhoneNo: service.userPhoneNo,
                                    userPhoneType: service.userPhoneType,
                                    emailNotified: service.emailNotified,
                                    streamingProvider: service.streamingProvider,
                                    eventType: service.eventType,
                                };
                            });
                        } else if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
                            this.headers = [
                                { text: 'Home', value: 'funeralHomeName', width: '16%', sortable: true },
                                { text: 'Event', value: 'eventTitle', width: '18%', sortable: true },
                                { text: 'Time', value: 'eventDateTime', width: '15%', align: 'center', sortable: true },
                                { text: 'Started', value: 'started', width: '10%', sortable: true },
                                { text: 'Success or Issues', value: 'eventIssue', width: '12%', sortable: false },
                                { text: 'Notes', value: 'eventNotes', width: '15%', align: 'center' },
                            ];
                            this.totalServices = response.data.dailyLiveStream.total;

                            this.services = response.data.dailyLiveStream.data.map(service => {
                                return {
                                    serviceSlug: service.serviceSlug,
                                    eventId: service.eventId,
                                    funeralHomeId: service.funeralHomeId,
                                    eventDateTime: service.eventDateTime,
                                    funeralHomeName: service.funeralHomeName,
                                    eventTitle: service.eventTitle,
                                    created: service.createdDate,
                                    createdBy: service.creatorEmail,
                                    started: service.started,
                                    eventIssue: service.eventIssue,
                                    providerId: service.providerId,
                                    totalEvents: service.totalEvents,
                                    eventNotes: service.eventNotes,
                                };
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.showSnackbar({
                            message:
                                'There was an error retrieving your data. Please contact support or try again in a few minutes.',
                        });
                    }

                    this.loading = false;
                } else {
                    try {
                        const response = await this.axios
                            .create({ headers: { Authorization: `Bearer ${this.token}` } })
                            .get(process.env.VUE_APP_API + '/admin-tools/daily-stream-sheet', {
                                params: {
                                    search: this.mysearch || '',
                                    startDate: moment(new Date(this.date)).utc(false).toISOString(),
                                    endDate: moment(this.streamsEndDate).utc(false).hours(23).minutes(59).toISOString(),
                                    // startDate: this.startDate ? new Date(this.startDate).toISOString() : this.getDate(),
                                    // endDate: this.endDate ? new Date(this.endDate).toISOString() : this.getDate(),
                                },
                            });
                        if (this.$auth.role.includes('SuperAdmin')) {
                            this.headers = [
                                // { text: 'Id', value: 'eventId', width: '18%', sortable: true },
                                { text: 'Home', value: 'funeralHomeName', width: '16%' },
                                { text: 'Event', value: 'eventTitle', width: '18%' },
                                { text: 'Time', value: 'eventDateTime', align: 'center', width: '15%' },
                                { text: 'Started', value: 'started', width: '10%' },
                                { text: 'Status', value: 'eventStatus', width: '14%' },
                                { text: 'Success or Issues', value: 'eventIssue', width: '12%' },
                                { text: 'Notes', value: 'eventNotes', sortable: true, width: '25%', align: 'center' },
                            ];

                            this.totalServices = response.data.dailyLiveStream.total;
                            this.services = response.data.dailyLiveStream.data.map(service => {
                                return {
                                    serviceSlug: service.serviceSlug,
                                    eventId: service.eventId,
                                    funeralHomeId: service.funeralHomeId,
                                    eventDateTime: service.eventDateTime,
                                    funeralHomeName: service.funeralHomeName,
                                    eventTitle: service.eventTitle,
                                    created: service.createdDate,
                                    createdBy: service.creatorEmail,
                                    started: service.started,
                                    eventStatus: service.eventStatus,
                                    eventIssue: service.eventIssue,
                                    eventNotes: service.eventNotes,
                                    totalEvents: service.totalEvents,
                                };
                            });
                        } else if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
                            this.headers = [
                                { text: 'Home', value: 'funeralHomeName', width: '16%', sortable: true },
                                { text: 'Event', value: 'eventTitle', width: '18%', sortable: true },
                                { text: 'Time', value: 'eventDateTime', width: '15%', sortable: true },
                                { text: 'Started', value: 'started', width: '10%', sortable: true },
                                { text: 'Success or Issues', value: 'eventIssue', width: '12%', sortable: false },
                                { text: 'Notes', value: 'eventNotes', sortable: true, width: '25%', align: 'center' },
                            ];
                            this.totalServices = response.data.dailyLiveStream.total;
                            this.services = response.data.dailyLiveStream.data.map(service => {
                                return {
                                    serviceSlug: service.serviceSlug,
                                    eventId: service.eventId,
                                    funeralHomeId: service.funeralHomeId,
                                    eventDateTime: service.eventDateTime,
                                    funeralHomeName: service.funeralHomeName,
                                    eventTitle: service.eventTitle,
                                    created: service.createdDate,
                                    createdBy: service.creatorEmail,
                                    started: service.started,
                                    eventIssue: service.eventIssue,
                                    totalEvents: service.totalEvents,
                                };
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.showSnackbar({
                            message:
                                'There was an error retrieving your data. Please contact support or try again in a few minutes.',
                        });
                    }
                    this.loading = false;
                }
            }
        },
    },
    sockets: {
        NotifyLive(data) {
            this.getServices();
        },
    },
    computed: {
        allIssuesSelected() {
            const filtered = this.issues.filter(issue => issue.title !== '_DIVIDER_');
            return filtered.length === this.issuesSelected.length;
        },
        allStatusSelected() {
            return this.statusSelected.length === this.eventStates.length;
        },
    },
    mounted: async function () {
        this.checkOffset();
        let string1 = '';
        let string2 = '';
        this.statusSelected.forEach(status => (string1 += `&status=${status}`));
        this.statusSelectedParamString = string1;

        this.issuesSelected.forEach(issue => {
            let issueValue = this.issues.find(issueItem => issueItem?.value === issue);
            string2 += `&issue=${issueValue.value}`;
        });

        // Get local settings
        if (this.$auth.role.includes('SuperAdmin')) {
            // await this.getServices()
            this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
            });

            const settings = await getLocalSettings();
            this.search = settings.serviceTable.search || '';
            this.options = {
                itemsPerPage: 100,
                page: settings.serviceTable.page || 1,
            };

            this.tableLoading = false;
        }
    },
    async created() {
        this.debouncedGetServices = debounce(this.getServices, 500);
        await this.setAuthToken();
        this.createAxiosInstance();
        // this.getServices();
    },
};
</script>

<style>
.solid-tooltip {
    opacity: 1 !important;
}
.search-bar {
    margin-left: auto;
    width: 30%;
}

.chip-container {
    position: relative;
    z-index: 1;
}

.chip {
    --border-radius: 15px;
    background: var(--background-color);
    border-radius: var(--border-radius);
    color: white;
    padding: 0.25em 0.7em;
    white-space: nowrap;
}

.chip--live {
    position: relative;
}

.chip--live::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: var(--border-radius);
    background-color: rgb(255, 82, 82);
    animation: 2s infinite pulse;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    70% {
        transform: scale(1.4);
        opacity: 0;
    }

    100% {
        transform: scale(0.95);
        opacity: 0;
    }
}

.text-callout {
    background: #2275d7;
    color: #fff;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    opacity: 0.9;
    display: inline-block;
    font-size: 0.7rem;
    vertical-align: middle;
}

.text-callout-incomplete {
    background: #f6ff0c;
    color: rgb(31, 31, 31);
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    opacity: 0.9;
    display: inline-block;
    font-size: 0.7rem;
    vertical-align: middle;
}

.selectIssue.v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
}
.selectIssue.v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
}

.blob-stream-sheet {
    display: inline-flex;
    border-radius: 50%;
    margin: 0 0.5rem;
    height: 25px !important;
    width: 25px !important;
    vertical-align: middle;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
    justify-content: center;
    align-items: center;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}
</style>
